<template>
    <TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="name"
        initSortOrder="desc"
        :filters="filters"
        addButtonName="Add Benchmark">

        <template v-slot:form_items="{formdata}">
            <el-form-item label="DS Ticker" prop="ticker">
                <el-input v-model="formdata.ticker" maxlength="100" minlength="3" />
            </el-form-item>
            <el-form-item label="Reuters ON" prop="actual">
                <el-checkbox v-model="formdata.actual"></el-checkbox>
            </el-form-item>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'source_benchmarks',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
            module: 'sourcesBenchmarksCodes',
            form_initial: {
                actual: true
            },
            formRules: {
                ticker: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ]
            },
            tableColumns: [
                {
                    field  : 'ticker', 
                    title  : 'DS Ticker', 
                    minWidth  : 150, 
                    params : {
                        filter : 'input', 
                    },
                },{
                    field  : 'actual', 
                    title  : 'Reuters ON', 
                    width  : 120, 
                    align  :'center',
                    params   : {
                        filter : 'boolean', 
                        editor : 'checkbox',
                    },
                },
            ],
            filters: {
                ticker : { value: undefined },
                name   : { value: undefined },
                actual : { value: undefined, strict: true },
            }
        }
    },

    computed: {
        ...mapState({
            user: state => state.app.user,
        }),

        rights(){
            return {
                view : (this.user && this.user.rights) ? this.user.rights['get-portf-settings']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-portf-settings']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-portf-settings']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-portf-settings']: false,
            }
        }
    },
}
</script>
